import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import { combineReducers } from "redux-immutable";
import {
  createAsyncFlagReducer,
  createAsyncReducer,
} from "../helpers/ReducerFactory";
import { ACTIVE } from "../constants/SubscriptionStatusTypes";
import { DUBAI_CENTER } from "../components/maps/GoogleMapWrapper";
import { formatPriceRegex } from "../helpers/FormatUtils";
import { injectReducer } from "../../shared/helpers/ReducerContext";
import { FOOD_DELIVERY } from "../constants/BusinessTypesCodes";

export const FETCH_MARKETPLACE = "MARKETPLACE/FETCH_MARKETPLACE";
export const UPDATE_MARKETPLACE = "MARKETPLACE/UPDATE_MARKETPLACE";

const selector = injectReducer(
  "marketplace",
  combineReducers({
    item: createAsyncReducer(
      FETCH_MARKETPLACE,
      Map({ marketplace: null, failed: false }),
      {
        pending: state => state.merge({ failed: false }),
        fulfilled: (state, action) =>
          state.merge({
            failed: false,
            marketplace: fromJS(action.payload.data.data),
          }),
        rejected: state => state.merge({ marketplace: null, failed: true }),
      },
    ),
    updating: createAsyncFlagReducer(UPDATE_MARKETPLACE),
  }),
);

export function getMarketplace(state) {
  return selector(state).getIn(["item", "marketplace"]);
}
export function getMarketplaceSubscription(state) {
  return selector(state).getIn(["item", "marketplace", "subscription"]);
}

export function isMarketplaceFetchFailed(state) {
  return selector(state).getIn(["item", "failed"]);
}

export function getMarketplaceId(state) {
  return selector(state).getIn(["item", "marketplace", "marketplace_id"]);
}
export const getMarketplaceBusinessType = state =>
  selector(state).getIn(["item", "marketplace", "business_type"], null);
export const isMarketplaceBusinessTypeFoodDelivery = state =>
  getMarketplaceBusinessType(state) === FOOD_DELIVERY;

export const isMarketplaceBusinessTypeCargo = state =>
  getMarketplaceBusinessType(state) === "CARGO";

export function isCustomMarketplace(state) {
  return selector(state).getIn(["item", "marketplace", "custom"]);
}

export function getMarketplaceSettings(state) {
  return selector(state).getIn(
    ["item", "marketplace", "setting", "settings"],
    Map(),
  );
}
export function getMarketplaceSettings2(state) {
  return selector(state).getIn(["item", "marketplace", "setting", "settings"]);
}

export function getMarketplaceZoneId(state) {
  return fp.toFinite(selector(state).getIn(["item", "marketplace", "zone_id"]));
}

export function getMarketplaceName(state) {
  return selector(state).getIn(["item", "marketplace", "name"]);
}

export function getMarketplaceReceiveOrderFromWing(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("receiveWingOrders", false);
}

export const isEReceiptOptionEnabled = state =>
  getMarketplaceSettings(state).get("defaultEReceiptOptionEnabled", false);

export function getMarketplaceEnableAssignDriver(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("enableAssignDriverForServiceTypes", List());
}

export function getMarketplaceDefaultBatchUpdateStatuses(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("defaultBatchUpdateStatuses", List());
}

export function getMarketplaceNumberFormat(state) {
  const decimalPoint = getMarketplaceDecimalPoint(state);

  return formatPriceRegex(decimalPoint);
}

export function getMarketplaceDecimalPoint(state) {
  const settings = getMarketplaceSettings(state);
  const decimalPoint = settings.get("decimalPoint", 2);

  return decimalPoint >= 0 ? decimalPoint : 2;
}

export function getMarketplaceCountry(state) {
  return selector(state).getIn(["item", "marketplace", "country"]);
}

export function getMarketplaceMapCenter(state) {
  return {
    lat: selector(state).getIn(
      ["item", "marketplace", "country", "lat"],
      DUBAI_CENTER.lat,
    ),
    lng: selector(state).getIn(
      ["item", "marketplace", "country", "lon"],
      DUBAI_CENTER.lng,
    ),
  };
}

export function getMarketplaceCurrency(state) {
  return selector(state).getIn(["item", "marketplace", "currency"]);
}

export function getMarketplaceInternationalEnabled(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("disableInternationalOrders", false);
}

export function getMarketplaceCODTransferEnabled(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("customCodTransferEnabled", false);
}

export function marketplaceGenerateTaxInvoiceEnabled(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("generateTaxInvoice", false);
}

export function getMarketplaceFavicon(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("favicon", null);
}

export function getMarketplaceDefaultLanguage(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("defaultLanguage", "en");
}

export function getMarketplaceDefaultUnitOfDistance(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("defaultUnitOfDistance", "km");
}

export function isNewModelSupported(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("newModelEnabled", false);
}

export function getMarketplaceActualWeight(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("actualWeight", 0);
}

export function getMarketplaceSkipItem(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("skipVolumetricStep", false);
}

export function enableMarketplaceTermsConditions(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("enableTermsConditions", false);
}

export function showMarketplaceAlternatePhoneNumbers(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("showAlternatePhoneFieldsOnOrderForm", false);
}
export function getMarketplaceReverseSetting(state) {
  return selector(state).getIn(
    ["item", "marketplace", "setting", "settings", "reverseLogisticEnabled"],
    false,
  );
}
export function supplierTransitOrdersVisibility(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("supplierTransitOrdersVisibility", false);
}

export function showDriverDynamicRunSheet(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("driverDynamicRunSheet", false);
}

export function marketplaceMandatoryFieldsDisabled(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("disableMandatoryFieldsOnOrderForm", false);
}

export function marketplaceDefaultOrderCreationEnabled(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("defaultOrderCreationEnabled", false);
}

export function getMarketplaceLogo(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("logo", null);
}

export function getMarketplaceLoginLogo(state) {
  const settings = getMarketplaceSettings(state);

  return settings.get("login_logo", null);
}

export function isMarketplaceTrial(state) {
  return selector(state).getIn(["item", "marketplace", "trial"], false);
}

export function getMarketplaceEndsInDays(state) {
  return selector(state).getIn(
    ["item", "marketplace", "trial_ends_in_days"],
    0,
  );
}

export function getMarketplaceFreeTrialEnds(state) {
  return selector(state).getIn(
    ["item", "marketplace", "free_trial_ends"],
    null,
  );
}

export const isMarketplaceSubscriptionStatusActive = state => {
  const status = selector(state).getIn(
    ["item", "marketplace", "subscription", "status"],
    "undefined",
  );
  if (status === "undefined") {
    return false;
  }
  return status === ACTIVE;
};

export function isEnableEventDateAtBatchUpdate(state) {
  const settings = getMarketplaceSettings(state);
  return settings.get("enableEventDateAtBatchUpdate", true);
}

export function isEnableDriverPhoneNumber(state) {
  const settings = getMarketplaceSettings(state);
  return settings.get("enableDriverPhoneNumberCustomerItem", true);
}

export function isEnableVehicleMake(state) {
  const settings = getMarketplaceSettings(state);
  return settings.get("enableVehicleMake", true);
}

export function isEnableCancelOrderForCustomer(state) {
  const settings = getMarketplaceSettings(state);
  return settings.get("enableCancelOrderCustomerItem", true);
}

export function isOtpSettingsServiceType(state) {
  const settings = getMarketplaceSettings(state);
  return settings.getIn(["otpSettings", "service_types"], List());
}

export function isOtpSettingsTransportationType(state) {
  const settings = getMarketplaceSettings(state);
  return settings.getIn(["otpSettings", "transportation_types"], List());
}

export function isMarketplaceFetching(state) {
  return selector(state).getIn(["item", "fetching"]);
}

export function isMarketplaceUpdating(state) {
  return selector(state).get("updating");
}

export function getMarketplaceStripeId(state) {
  return selector(state).getIn(
    ["item", "marketplace", "subscription", "stripe_customer_id"],
    null,
  );
}

export function isReverseLogisticEnabled(state) {
  const settings = getMarketplaceSettings(state);
  return settings.get("reverseLogisticEnabled", false);
}

export function customsFeePercentage(state) {
  const settings = getMarketplaceSettings(state);
  return settings.get("customsFeePercentage", 0);
}
