import { PLANS, PROFILE, PURCHASE_HISTORY } from "./ProfileTabs";
import { ACTIVE, ALL, INACTIVE } from "./OverallStatusTabs";
import {
  GENERAL as MARKETPLACE_DETAILS_TAB,
  LOGO as MARKETPLACE_LOGO_TAB,
  SETTINGS as MARKETPLACE_SETTINGS_TAB,
} from "./MarketplaceItemTabs";
import {
  COMMUNICATION as SUPPLIER_ITEM_TAB_COMMUNICATION,
  CURRENCY as SUPPLIER_ITEM_TAB_CURRENCY,
  MONTHLY_QUOTA as SUPPLIER_ITEM_TAB_MONTHLY_QUOTA,
  NEIGHBORHOODS as SUPPLIER_ITEM_TAB_NEIGHBORHOODS,
  PACKAGES as SUPPLIER_ITEM_TAB_PACKAGES,
} from "./AdminSupplierItemTabs";
import {
  DETAILS as WAREHOUSE_ITEM_TAB_DETAILS,
  DRIVERS as WAREHOUSE_ITEM_TAB_DRIVERS,
  MARKETPLACE as WAREHOUSE_ITEM_TAB_MARKETPLACE,
  NEIGHBOURHOODS as WAREHOUSE_ITEM_TAB_NEIGHBOURHOODS,
  OPERATORS as WAREHOUSE_ITEM_TAB_OPERATORS,
} from "./AdminWarehouseItemTabs";
import { DASHBOARD_URL, PROFILE_URL } from "./DashboardPathConstants";
import {
  ALL as ALL_VEHICLE,
  WITH_ORDER as VEHICLE_WITH_ORDER,
  WITHOUT_DRIVER as VEHICLE_WITHOUT_DRIVER,
} from "./AdminVehicleFilterTypes";

export const CHARTS_PATH = "charts";
export const CHARTS_URL = `${DASHBOARD_URL}/charts`;

export const INTERNATIONAL_CHARTS_PATH = "international-charts";
export const INTERNATIONAL_CHARTS_URL = `${DASHBOARD_URL}/international-charts`;

// Order
export const ORDER_LIST_PATH = "order/list";
export const ARCHIVE_ORDER_LIST_PATH = "archive/order/list";
export const SAMPLE_ORDER_LIST_PATH = "sample/order/list";
export const SAMPLE_ORDER_ITEM_PATH = "sample/order/item/:id";
export const SAMPLE_ORDER_ITEM_LIST_PATH = "sample/order/item/list/:id";
export const ORDER_JOBS_PATH = "order-jobs";
export const ORDER_TRACK_PATH = "order/track";
export const ORDER_UPLOAD_PATH = "order/upload";
export const ORDER_UPLOAD_V2_PATH = "order/upload_v2";
export const ORDER_LIST_MAP_PATH = "order/map";
export const ORDER_SORTING_VIEW_PATH = "order/sorting";
export const ORDER_SORTING_MULTIBOX_VIEW_PATH = "order/sorting-multibox";
export const ORDER_BIN_VALIDATION_VIEW_PATH = "order/bin-validation";
export const ORDER_BIN_VALIDATION_SIMPLE_VIEW_PATH = "order/inbound-sorting";
export const ORDER_BIN_VALIDATION_SIMPLE_FIREBASE_VIEW_PATH =
  "order/inbound-sorting/firebase";
export const ORDER_OUTBOUND_SORTING_VIEW_PATH = "order/outbound-sorting";
export const ORDER_OFFLINE_OUTBOUND_SORTING_VIEW_PATH =
  "order/offline-outbound-sorting";
export const ORDER_OUTBOUND_SORTING_FIREBASE_VIEW_PATH =
  "order/outbound-sorting/firebase";
export const ORDER_OUTBOUND_REGISTRY_SORTING_VIEW_PATH =
  "order/outbound-registry-sorting";
export const ORDER_OFFLINE_OUTBOUND_REGISTRY_SORTING_VIEW_PATH =
  "order/offline-outbound-registry-sorting";
export const ORDER_OUTBOUND_CONSOLIDATED_VIEW_PATH =
  "order/outbound-consolidated";
export const ORDER_OFFLINE_OUTBOUND_CONSOLIDATED_VIEW_PATH =
  "order/offline-outbound-consolidated";
export const ORDER_OUTBOUND_CONSOLIDATED_FIREBASE_VIEW_PATH =
  "order/outbound-consolidated-firebase";
export const ORDER_OUTBOUND_CONTAINER_VIEW_PATH = "order/outbound-container";
export const ORDER_OUTBOUND_CONTAINER_FIREBASE_VIEW_PATH =
  "order/outbound-container-firebase";
export const ORDER_OFFLINE_OUTBOUND_CONTAINER_FIREBASE_VIEW_PATH =
  "order/offline-outbound-container";
export const ORDER_OUTBOUND_ROUTES_VIEW_PATH = "order/outbound-routes";
export const ORDER_OUTBOUND_ROUTES_FIREBASE_VIEW_PATH =
  "order/outbound-routes-firebase";
export const ORDER_CUSTOMS_LIST_VIEW_PATH = "order/customs/list";
export const ORDER_CUSTOMS_FEE_MANAGEMENT_LIST_VIEW_PATH =
  "customs/fee/management/list";
export const ORDER_CUSTOMS_ORDERS_LIST_VIEW_PATH = "order/customs/:id/orders";
export const ORDER_CUSTOMS_INBOUND_SORTING_VIEW_PATH =
  "order/customs/inbound-sorting";
export const ORDER_CUSTOMS_OUTBOUND_SORTING_VIEW_PATH =
  "order/customs/outbound-sorting";
export const VOICE_SORTING_PATH = "order/voice-sorting";
export const ORDER_RETURN_VIEW_PATH = "order/return";
export const ORDER_LIST_REQUIRED_ATTENTION_PATH =
  "order/required-attention(/:tab)";

export const ORDER_OUTBOUND_ROUTES_OFFLINE_VIEW_PATH =
  "order/outbound-routes-offline";

export const ORDER_LIST_URL = `${DASHBOARD_URL}/order/list`;
export const ARCHIVE_ORDER_LIST_URL = `${DASHBOARD_URL}/archive/order/list`;
export const SAMPLE_ORDER_LIST_URL = `${DASHBOARD_URL}/sample/order/list`;
export const SAMPLE_ORDER_ITEM_URL = `${DASHBOARD_URL}/sample/order/item/`;
export const SAMPLE_ORDER_ITEM_LIST_URL = `${DASHBOARD_URL}/sample/order/item/list/`;
export const ORDER_JOBS_URL = `${DASHBOARD_URL}/order-jobs`;
export const ORDER_TRACK_URL = `${DASHBOARD_URL}/order/track`;
export const ORDER_UPLOAD_URL = `${DASHBOARD_URL}/order/upload`;
export const ORDER_UPLOAD_V2_URL = `${DASHBOARD_URL}/order/upload_v2`;
export const ORDER_BATCH_UPDATE_URL = `${DASHBOARD_URL}/order/batch-update`;
export const ORDER_BATCH_UPDATE_PATH = "order/batch-update";
export const ROUTE_TEMPLATES_URL = `${DASHBOARD_URL}/order/route_template`;
export const ROUTE_TEMPLATES_PATH = "order/route_template";
export const ROUTE_TEMPLATE_CREATE_PATH = "order/route_template/create";
export const ROUTE_TEMPLATE_CREATE_URL = `${DASHBOARD_URL}/${ROUTE_TEMPLATE_CREATE_PATH}`;
export const ROUTE_TEMPLATE_ITEM_PATH = "order/route_template/item/:id";
export const ROUTE_TEMPLATE_ITEM_URL = `${DASHBOARD_URL}/order/route_template/item/`;
export const ORDER_LIST_MAP_URL = `${DASHBOARD_URL}/order/map`;
export const ORDER_SORTING_VIEW_URL = `${DASHBOARD_URL}/order/sorting`;
export const ORDER_SORTING_MULTIBOX_VIEW_URL = `${DASHBOARD_URL}/order/sorting-multibox`;
export const ORDER_BIN_VALIDATION_VIEW_URL = `${DASHBOARD_URL}/order/bin-validation`;
export const ORDER_BIN_VALIDATION_SIMPLE_VIEW_URL = `${DASHBOARD_URL}/order/inbound-sorting`;
export const ORDER_BIN_VALIDATION_SIMPLE_FIREBASE_VIEW_URL = `${DASHBOARD_URL}/order/inbound-sorting/firebase`;
export const ORDER_OUTBOUND_SORTING_VIEW_URL = `${DASHBOARD_URL}/order/outbound-sorting`;
export const ORDER_OFFLINE_OUTBOUND_SORTING_VIEW_URL = `${DASHBOARD_URL}/order/offline-outbound-sorting`;
export const ORDER_OUTBOUND_SORTING_FIREBASE_VIEW_URL = `${DASHBOARD_URL}/order/outbound-sorting/firebase`;
export const ORDER_OUTBOUND_REGISTRY_SORTING_VIEW_URL = `${DASHBOARD_URL}/order/outbound-registry-sorting`;
export const ORDER_OFFLINE_OUTBOUND_REGISTRY_SORTING_VIEW_URL = `${DASHBOARD_URL}/order/offline-outbound-registry-sorting`;
export const ORDER_OUTBOUND_REGISTRY_SORTING_FIREBASE_VIEW_URL = `${DASHBOARD_URL}/order/outbound-registry-sorting/firebase`;
export const ORDER_OUTBOUND_CONSOLIDATED_VIEW_URL = `${DASHBOARD_URL}/order/outbound-consolidated`;
export const ORDER_OFFLINE_OUTBOUND_CONSOLIDATED_VIEW_URL = `${DASHBOARD_URL}/order/offline-outbound-consolidated`;
export const ORDER_OUTBOUND_CONSOLIDATED_FIREBASE_VIEW_URL = `${DASHBOARD_URL}/order/outbound-consolidated-firebase`;
export const ORDER_OUTBOUND_CONTAINER_VIEW_URL = `${DASHBOARD_URL}/order/outbound-container`;
export const ORDER_OUTBOUND_CONTAINER_FIREBASE_VIEW_URL = `${DASHBOARD_URL}/order/outbound-container-firebase`;
export const ORDER_OFFLINE_OUTBOUND_CONTAINER_FIREBASE_VIEW_URL = `${DASHBOARD_URL}/order/offline-outbound-container`;
export const ORDER_OUTBOUND_ROUTES_VIEW_URL = `${DASHBOARD_URL}/order/outbound-routes`;
export const ORDER_CUSTOMS_LIST_VIEW_URL = `${DASHBOARD_URL}/order/customs/list`;
export const ORDER_CUSTOMS_FEE_MANAGEMENT_LIST_VIEW_URL = `${DASHBOARD_URL}/customs/fee/management/list`;
export const ORDER_CUSTOMS_ORDERS_LIST_VIEW_URL = `${DASHBOARD_URL}/order/customs/%s/orders`;
export const ORDER_CUSTOMS_INBOUND_SORTING_VIEW_URL = `${DASHBOARD_URL}/order/customs/inbound-sorting`;
export const ORDER_CUSTOMS_OUTBOUND_SORTING_VIEW_URL = `${DASHBOARD_URL}/order/customs/outbound-sorting`;
export const ORDER_OUTBOUND_ROUTES_FIREBASE_VIEW_URL = `${DASHBOARD_URL}/order/outbound-routes-firebase`;
export const ORDER_OUTBOUND_ROUTES_OFFLINE_VIEW_URL = `${DASHBOARD_URL}/order/outbound-routes-offline`;
export const ORDER_RETURN_VIEW_URL = `${DASHBOARD_URL}/order/return`;
export const ORDER_LIST_REQUIRED_ATTENTION_URL = `${DASHBOARD_URL}/order/required-attention`;

export const JOBS_PATH = "jobs";
export const JOBS_URL = `${DASHBOARD_URL}/jobs`;

export const LETTERS_PATH = "letters";
export const DISPATCH_ITEM_PATH = "letters/dispatch/:id";
export const RECEPTACLE_ITEM_PATH = "letters/receptacle/item/:id/:dispatchId";
export const LETTERS_URL = `${DASHBOARD_URL}/letters`;
export const DISPATCH_ITEM_URL = `${DASHBOARD_URL}/letters/dispatch/`;
export const RECEPTACLE_ITEM_URL = `${DASHBOARD_URL}/letters/receptacle/item/%s/%s`;
export const PI1_PATH = "pi1";
export const PI1_URL = `${DASHBOARD_URL}/pi1`;
export const REPORTS_PATH = "reports";
export const REPORTS_URL = `${DASHBOARD_URL}/reports`;

// Admin Areas
export const ADMIN_AREAS_PATH = "admin-areas";
export const ADMIN_AREAS_URL = `${DASHBOARD_URL}/admin-areas`;

// Areas
export const AREAS_PATH = "areas";
export const AREAS_URL = `${DASHBOARD_URL}/areas`;

// Areas
export const NEW_AREAS_PATH = "areas/new";
export const NEW_AREAS_URL = `${DASHBOARD_URL}/areas/new`;

// Finance
export const FINANCE_ORDER_LIST_PATH = "finance/order/list(/:codStatus)";
export const FINANCE_TRANSFER_ITEM_PATH = "finance/transfer/item/:id";
export const FINANCE_TRANSFER_LIST_PATH =
  "finance/transfer/list(/:transferStatus)";
export const FINANCE_WITHDRAW_ITEM_PATH = "finance/withdraw/item/:id";
export const FINANCE_WITHDRAW_LIST_PATH =
  "finance/withdraw/list(/:transferStatus)";

export const FINANCE_ORDER_LIST_URL = `${DASHBOARD_URL}/finance/order/list`;
export const FINANCE_TRANSFER_LIST_URL = `${DASHBOARD_URL}/finance/transfer/list`;
export const FINANCE_TRANSFER_ITEM_URL = `${DASHBOARD_URL}/finance/transfer/item/`;
export const FINANCE_WITHDRAW_LIST_URL = `${DASHBOARD_URL}/finance/withdraw/list`;
export const FINANCE_WITHDRAW_ITEM_URL = `${DASHBOARD_URL}/finance/withdraw/item/`;

export const FINANCE_SETTLEMENT_PATH = "finance/settlement";
export const FINANCE_SETTLEMENT_URL = `${DASHBOARD_URL}/finance/settlement`;

export const FINANCE_TRANSACTIONS_PATH = "finance/transactions";
export const FINANCE_TRANSACTIONS_URL = `${DASHBOARD_URL}/finance/transactions`;

export const FINANCE_ORDER_EXCEPTIONS_PATH = "finance/order-exceptions";
export const FINANCE_ORDER_EXCEPTIONS_URL = `${DASHBOARD_URL}/finance/order-exceptions`;

// Bank Deposits
export const FINANCE_BANK_DEPOSIT_ORDERS_PATH = "finance/bank-deposit-orders";
export const FINANCE_BANK_DEPOSIT_ORDERS_URL = `${DASHBOARD_URL}/finance/bank-deposit-orders`;
export const FINANCE_BANK_DEPOSITS_PATH = "finance/bank-deposits";
export const FINANCE_BANK_DEPOSITS_URL = `${DASHBOARD_URL}/finance/bank-deposits`;
export const FINANCE_BANK_DEPOSIT_ITEM_PATH = "finance/bank-deposit/item/:id";
export const FINANCE_BANK_DEPOSIT_ITEM_URL = `${DASHBOARD_URL}/finance/bank-deposit/item/`;

// Cashiers
export const FINANCE_CASHIERS_PATH = "finance/cashiers/list(/:statusCode)";
export const FINANCE_CASHIERS_URL = `${DASHBOARD_URL}/finance/cashiers/list`;

// Customer

export const CUSTOMER_LIST_PATH = "customer/list(/:statusCode)";
export const CUSTOMER_ITEM_PATH = "customer/item(/:customerId)(/:tab)";

const CUSTOMER_LIST_URL = `${DASHBOARD_URL}/customer/list`;

export const CUSTOMER_LIST_ALL_URL = `${CUSTOMER_LIST_URL}/${ALL}`;
export const CUSTOMER_LIST_ACTIVE_URL = `${CUSTOMER_LIST_URL}/${ACTIVE}`;
export const CUSTOMER_LIST_INACTIVE_URL = `${CUSTOMER_LIST_URL}/${INACTIVE}`;

export const CUSTOMER_ITEM_URL = `${DASHBOARD_URL}/customer/item/`;
export const CUSTOMER_CREATE_URL = `${DASHBOARD_URL}/customer/create/`;
export const CUSTOMER_CREATE_PATH = `customer/create/`;

// Driver

export const DRIVER_LIST_PATH = "driver/list";
export const DRIVER_RADAR_PATH = "driver/maps";
export const DRIVER_NEW_RADAR_PATH = "driver/new-maps";

export const DRIVER_LIST_URL = `${DASHBOARD_URL}/driver/list`;
export const DRIVER_RADAR_URL = `${DASHBOARD_URL}/driver/maps`;
export const DRIVER_NEW_RADAR_URL = `${DASHBOARD_URL}/driver/new-maps`;

export const DRIVER_ORDER_RADAR_PATH = "order/radar";
export const DRIVER_ORDER_RADAR_URL = `${DASHBOARD_URL}/order/radar`;

export const DRIVERS_ATTENDANCE_PATH = "drivers-attendance";
export const DRIVERS_ATTENDANCE_URL = `${DASHBOARD_URL}/drivers-attendance`;

export const DRIVER_LIABILITY_PATH = "driver-liability";
export const DRIVER_LIABILITY_URL = `${DASHBOARD_URL}/driver-liability`;

export const OPERATOR_BALANCE_PATH = "operator-balance";
export const OPERATOR_BALANCE_URL = `${DASHBOARD_URL}/operator-balance`;
export const OPERATOR_BALANCE_REFUND_PATH =
  "operator-balance/refund(/:id)(/:name)";
export const OPERATOR_BALANCE_REFUND_URL = `${DASHBOARD_URL}/operator-balance/refund/`;

export const DRIVERS_DASHBOARD_PATH = "drivers-dashboard";
export const DRIVERS_DASHBOARD_URL = `${DASHBOARD_URL}/drivers-dashboard`;

// Users

export const USERS_LIST_PATH = "users/list";
export const USERS_LIST_URL = `${DASHBOARD_URL}/users/list`;
export const USERS_PERMISSION_LIST_PATH = `${DASHBOARD_URL}/users/permissions/list`;
export const USERS_PERMISSION_FORM_PATH = `${DASHBOARD_URL}/users/permissions/form`;

// Vehicle

export const VEHICLE_LIST_PATH = "vehicle/list(/:statusCode)";

const VEHICLE_LIST_URL = `${DASHBOARD_URL}/vehicle/list`;

export const VEHICLE_LIST_ALL_URL = `${VEHICLE_LIST_URL}/${ALL_VEHICLE}`;
export const VEHICLE_LIST_WITHOUT_DRIVER_URL = `${VEHICLE_LIST_URL}/${VEHICLE_WITHOUT_DRIVER}`;
export const VEHICLE_LIST_WITH_ORDER_URL = `${VEHICLE_LIST_URL}/${VEHICLE_WITH_ORDER}`;

// Warehouse

export const WAREHOUSE_CREATE_PATH = "warehouse/create";
export const WAREHOUSE_LIST_PATH = "warehouse/list";
export const WAREHOUSE_ITEM_PATH = "warehouse/item(/:warehouseId)(/:tab)";

export const WAREHOUSE_CREATE_URL = `${DASHBOARD_URL}/warehouse/create/`;

export const WAREHOUSE_LIST_URL = `${DASHBOARD_URL}/warehouse/list`;

// Locker post

export const LOCKER_POST_LIST_PATH = "locker_post/list";
export const LOCKER_POST_LIST_URL = `${DASHBOARD_URL}/locker_post/list`;

export const LOCKER_POST_ITEM_PATH = "locker_post/item/:id";
export const LOCKER_POST_CREATE_PATH = "locker_post/create";

export const LOCKER_POST_ITEM_URL = `${DASHBOARD_URL}/locker_post/item/`;
export const LOCKER_POST_CREATE_URL = `${DASHBOARD_URL}/locker_post/create`;

export const WAREHOUSE_ITEM_URL = `${DASHBOARD_URL}/warehouse/item/`;
export const WAREHOUSE_ITEM_DETAILS_URL = `${WAREHOUSE_ITEM_URL}%s/${WAREHOUSE_ITEM_TAB_DETAILS}`;
export const WAREHOUSE_ITEM_DRIVERS_URL = `${WAREHOUSE_ITEM_URL}%s/${WAREHOUSE_ITEM_TAB_DRIVERS}`;
export const WAREHOUSE_ITEM_OPERATORS_URL = `${WAREHOUSE_ITEM_URL}%s/${WAREHOUSE_ITEM_TAB_OPERATORS}`;
export const WAREHOUSE_ITEM_NEIGHBOURHOODS_URL = `${WAREHOUSE_ITEM_URL}%s/${WAREHOUSE_ITEM_TAB_NEIGHBOURHOODS}`;
export const WAREHOUSE_ITEM_MARKETPLACE_URL = `${WAREHOUSE_ITEM_URL}%s/${WAREHOUSE_ITEM_TAB_MARKETPLACE}`;
// Timeslots

export const TIMESLOT_LIST_PATH = "timeslot/list";
export const TIME_SLOT_LIST_URL = `${DASHBOARD_URL}/timeslot/list`;

// Timeslots Availability

export const TIMESLOT_AVAILABILITY_LIST_PATH = "timeslot/availability/list";
export const TIME_SLOT_AVAILABILITY_LIST_URL = `${DASHBOARD_URL}/timeslot/availability/list`;

// Supplier

export const SUPPLIER_LIST_PATH = "supplier/list";
export const SUPPLIER_ITEM_PATH = "supplier/item(/:supplierId)(/:tab)";

export const SUPPLIER_LIST_URL = `${DASHBOARD_URL}/supplier/list`;

export const SUPPLIER_ITEM_URL = `${DASHBOARD_URL}/supplier/item/`;
export const SUPPLIER_ITEM_MONTHLY_QUOTA_URL = `${SUPPLIER_ITEM_URL}%s/${SUPPLIER_ITEM_TAB_MONTHLY_QUOTA}`;
export const SUPPLIER_ITEM_NEIGHBORHOODS_URL = `${SUPPLIER_ITEM_URL}%s/${SUPPLIER_ITEM_TAB_NEIGHBORHOODS}`;
export const SUPPLIER_ITEM_PACKAGES_URL = `${SUPPLIER_ITEM_URL}%s/${SUPPLIER_ITEM_TAB_PACKAGES}`;
export const SUPPLIER_ITEM_COMMUNICATION_URL = `${SUPPLIER_ITEM_URL}%s/${SUPPLIER_ITEM_TAB_COMMUNICATION}`;
export const SUPPLIER_ITEM_CURRENCY_URL = `${SUPPLIER_ITEM_URL}%s/${SUPPLIER_ITEM_TAB_CURRENCY}`;

// Supplier User

export const SUPPLIER_USER_LIST_PATH = "supplier/user/list";
export const SUPPLIER_USER_LIST_URL = `${DASHBOARD_URL}/supplier/user/list`;

// Supplier Contacts

export const SUPPLIER_CONTACTS_LIST_PATH = "supplier/contacts/list";
export const SUPPLIER_CONTACTS_LIST_URL = `${DASHBOARD_URL}/supplier/contacts/list`;

// Promo

export const PROMO_LIST_PATH = "promo/list";
export const PROMO_ITEM_PATH = "promo/item/:id";
export const PROMO_CREATE_PATH = "promo/create";

export const PROMO_LIST_URL = `${DASHBOARD_URL}/promo/list`;
export const PROMO_ITEM_URL = `${DASHBOARD_URL}/promo/item/`;
export const PROMO_CREATE_URL = `${DASHBOARD_URL}/promo/create`;

// Address Book

export const ADDRESS_BOOK_LIST_PATH = "address-book/list";
export const ADDRESS_BOOK_ITEM_PATH = "address-book/item/:id";
export const ADDRESS_BOOK_CREATE_PATH = "address-book/create";

export const ADDRESS_BOOK_LIST_URL = `${DASHBOARD_URL}/address-book/list`;
export const ADDRESS_BOOK_ITEM_URL = `${DASHBOARD_URL}/address-book/item/`;
export const ADDRESS_BOOK_CREATE_URL = `${DASHBOARD_URL}/address-book/create`;

// Address Book V2

export const ADDRESS_BOOK_LIST_NEW_PATH = "address-book-new/list";
export const ADDRESS_BOOK_ITEM_NEW_PATH = "address-book-new/item/:id";
export const ADDRESS_BOOK_CREATE_NEW_PATH = "address-book-new/create";

export const ADDRESS_BOOK_LIST_NEW_URL = `${DASHBOARD_URL}/address-book-new/list`;
export const ADDRESS_BOOK_ITEM_NEW_URL = `${DASHBOARD_URL}/address-book-new/item/`;
export const ADDRESS_BOOK_CREATE_NEW_URL = `${DASHBOARD_URL}/address-book-new/create`;

// CASH_CERTIFICATE
export const CASH_CERTIFICATE_PATH = "cash_certificate";
export const CASH_CERTIFICATE_URL = `${DASHBOARD_URL}/cash_certificate`;

// Post order

export const POST_LIST_PATH = "post/list";
export const POST_ITEM_PATH = "post/item/:id";
export const POST_CREATE_PATH = "post/create";
export const SIMPLE_POST_CREATE_PATH = "simple/post/create";
export const SINGLE_SIMPLE_POST_CREATE_PATH = "single/simple/post/create";
export const SIMPLE_POST_ITEM_PATH = "simple/post/item/";

export const POST_LIST_URL = `${DASHBOARD_URL}/post/list`;
export const POST_ITEM_URL = `${DASHBOARD_URL}/post/item/`;
export const POST_CREATE_URL = `${DASHBOARD_URL}/post/create`;
export const SIMPLE_POST_CREATE_URL = `${DASHBOARD_URL}/simple/post/create`;
export const SINGLE_SIMPLE_POST_CREATE_URL = `${DASHBOARD_URL}/single/simple/post/create`;

// Customer Feedbacks

export const CUSTOMER_FEEDBACK_LIST_PATH = "customer/feedback/list";
export const CUSTOMER_FEEDBACK_LIST_URL = `${DASHBOARD_URL}/customer/feedback/list`;

// Shop Categories

export const SHOP_CATEGORY_LIST_PATH = "shop/category/list";
export const SHOP_CATEGORY_LIST_URL = `${DASHBOARD_URL}/shop/category/list`;

// Shop Venues

export const SHOP_VENUE_LIST_PATH = "shop/venue";
export const SHOP_VENUE_CREATE_PATH = "shop/venue/create";
export const SHOP_VENUE_ITEM_PATH = "shop/venue/item/:id";
export const SHOP_VENUE_LIST_URL = `${DASHBOARD_URL}/shop/venue`;
export const SHOP_VENUE_CREATE_URL = `${DASHBOARD_URL}/shop/venue/create`;
export const SHOP_VENUE_ITEM_URL = `${DASHBOARD_URL}/shop/venue/item/`;

export const SHOP_VENUE_MENU_LIST_PATH = `${DASHBOARD_URL}/shop/venue/:venueId/menu/`;
export const SHOP_VENUE_MENU_LIST_URL = `${DASHBOARD_URL}/shop/venue/%s/menu/`;

// Shop Menu

export const SHOP_MENU_LIST_PATH = "shop/menu";
export const SHOP_MENU_CREATE_PATH = "shop/menu/create";
export const SHOP_MENU_ITEM_PATH = "shop/menu/item/:id";
export const SHOP_MENU_LIST_URL = `${DASHBOARD_URL}/shop/menu`;
export const SHOP_MENU_CREATE_URL = `${DASHBOARD_URL}/shop/menu/create`;
export const SHOP_MENU_ITEM_URL = `${DASHBOARD_URL}/shop/menu/item/`;

// Shop Products

export const SHOP_PRODUCT_LIST_PATH = "shop/venue/:venueId/product";
export const SHOP_PRODUCT_CREATE_PATH = "shop/venue/:venueId/product/create";
export const SHOP_PRODUCT_ITEM_PATH = "shop/venue/:venueId/product/:productId";

export const SHOP_PRODUCT_LIST_URL = `${DASHBOARD_URL}/shop/venue/%s/product/`;
export const SHOP_PRODUCT_CREATE_URL = `${DASHBOARD_URL}/shop/venue/%s/product/create`;
export const SHOP_PRODUCT_ITEM_URL = `${DASHBOARD_URL}/shop/venue/%s/product/%s`;

// Shop Cities

export const SHOP_CITY_LIST_PATH = "shop/city/list";
export const SHOP_CITY_LIST_URL = `${DASHBOARD_URL}/shop/city/list`;

// Settings Dashboard

export const SETTINGS_DASHBOARD_PATH = "settings-dashboard";
export const SETTINGS_DASHBOARD_URL = `${DASHBOARD_URL}/settings-dashboard`;

// SHOP Venue Addresses

export const SHOP_ADDRESS_LIST_PATH = "shop/venue/:venueId/address";
export const SHOP_ADDRESS_CREATE_PATH = "shop/venue/:venueId/address/create";
export const SHOP_ADDRESS_ITEM_PATH = "shop/venue/:venueId/address/:addressId";

export const SHOP_ADDRESS_LIST_URL = `${DASHBOARD_URL}/shop/venue/%s/address/`;
export const SHOP_ADDRESS_CREATE_URL = `${DASHBOARD_URL}/shop/venue/%s/address/create`;
export const SHOP_ADDRESS_ITEM_URL = `${DASHBOARD_URL}/shop/venue/%s/address/%s`;

// Packages

export const DEFAULT_PACKAGE_PATH = "package/default";
export const DEFAULT_PACKAGE_URL = `${DASHBOARD_URL}/package/default`;
export const DEFAULT_PACKAGE_NEW_PATH = "package/default/new";
export const DEFAULT_PACKAGE_NEW_URL = `${DASHBOARD_URL}/package/default/new`;

// Communication Settings

export const COMMUNICATION_SETTINGS_LIST_PATH = "communication-settings/list";
export const COMMUNICATION_SETTINGS_CREATE_PATH =
  "communication-settings/create";
export const COMMUNICATION_SETTINGS_ITEM_PATH =
  "communication-settings/item/:id";

export const COMMUNICATION_SETTINGS_LIST_URL = `${DASHBOARD_URL}/communication-settings/list`;
export const COMMUNICATION_SETTINGS_CREATE_URL = `${DASHBOARD_URL}/communication-settings/create`;
export const COMMUNICATION_SETTINGS_ITEM_URL = `${DASHBOARD_URL}/communication-settings/item/`;

// Postcodes

export const POSTCODES_ITEM_PATH = "postcodes/item/:id";
export const POSTCODES_LIST_PATH = "postcodes/list";
export const POSTCODES_CREATE_PATH = "postcodes/create";

export const POSTCODES_ITEM_URL = `${DASHBOARD_URL}/postcodes/item/`;
export const POSTCODES_LIST_URL = `${DASHBOARD_URL}/postcodes/list`;
export const POSTCODES_CREATE_URL = `${DASHBOARD_URL}/postcodes/create`;

export const ORDERS_PER_POSTCODES_PATH = "orders-per-postcodes";
export const ORDERS_PER_POSTCODES_URL = `${DASHBOARD_URL}/orders-per-postcodes`;

export const ORDERS_PER_POSTCODES_BETA_PATH = "orders-per-postcodes-beta";
export const ORDERS_PER_POSTCODES_BETA_URL = `${DASHBOARD_URL}/orders-per-postcodes-beta`;

export const POSTCODES_COORDINATES_PATH = "postcodes-coordinates";
export const POSTCODES_COORDINATES_URL = `${DASHBOARD_URL}/postcodes-coordinates`;

// Settings

export const SETTINGS_ORDER_PATH = "settings/orders";
export const ORDER_RULE_CREATE_PATH = "settings/order-rules/create";
export const ORDER_RULE_LIST_PATH = "settings/order-rules/list";
export const ORDER_RULE_ITEM_PATH = "settings/order-rules/item/:id";

export const SETTINGS_ORDER_URL = `${DASHBOARD_URL}/settings/orders`;

export const ORDER_RULE_CREATE_URL = `${DASHBOARD_URL}/settings/order-rules/create`;
export const ORDER_RULE_LIST_URL = `${DASHBOARD_URL}/settings/order-rules/list`;
export const ORDER_RULE_ITEM_URL = `${DASHBOARD_URL}/settings/order-rules/item/`;

export const SUPPLIER_FINANCE_SETTINGS_PATH = "settings/supplier-finance";
export const SUPPLIER_FINANCE_SETTINGS_URL = `${DASHBOARD_URL}/settings/supplier-finance`;

export const SCORE_SETTINGS_PATH = "settings/score-settings";
export const SCORE_SETTINGS_URL = `${DASHBOARD_URL}/settings/score-settings`;

export const HOLIDAY_SETTINGS_PATH = "settings/holiday-settings";
export const HOLIDAY_SETTINGS_URL = `${DASHBOARD_URL}/settings/holiday-settings`;

export const ORDER_RULES_GROUP_LIST_PATH = "settings/order-rules-group/list";
export const ORDER_RULES_GROUP_ITEM_PATH =
  "settings/order-rules-group/item(/:groupId)";

export const ORDER_RULES_GROUP_LIST_URL = `${DASHBOARD_URL}/settings/order-rules-group/list`;
export const ORDER_RULES_GROUP_ITEM_URL = `${DASHBOARD_URL}/settings/order-rules-group/item`;

// Reporting

export const REPORTING_ROOT_PATH = "reporting";
export const REPORTING_REPORTS_URL = `${DASHBOARD_URL}/reporting`;

export const REPORTING_OPERATIONAL_PATH = `reporting/operational`;
export const REPORTING_OPERATIONAL_URL = `${DASHBOARD_URL}/reporting/operational`;

export const REPORTING_FINANCIAL_PATH = `reporting/financial`;
export const REPORTING_FINANCIAL_URL = `${DASHBOARD_URL}/reporting/financial`;

// Bank

export const BANK_LIST_PATH = "bank/list";
export const BANK_LIST_URL = `${DASHBOARD_URL}/bank/list`;

export const BANK_SETTINGS_LIST_PATH = "settings/bank-reconcile";
export const BANK_SETTINGS_LIST_URL = `${DASHBOARD_URL}/settings/bank-reconcile`;
export const BANK_SETTINGS_CREATE_PATH = "settings/bank-reconcile";
export const BANK_SETTINGS_CREATE_URL = `${DASHBOARD_URL}/settings/bank-reconcile`;

// Call Step Settings
export const CALL_STEP_SETTINGS_LIST_PATH = "call-step-settings/list";

export const CALL_STEP_SETTINGS_LIST_URL = `${DASHBOARD_URL}/call-step-settings/list`;

// SLA Management Settings
export const SLA_RULE_CREATE_PATH = "settings/sla-rules/create";
export const SLA_RULE_LIST_PATH = "settings/sla-rules/list";
export const SLA_RULE_ITEM_PATH = "settings/sla-rules/item/:id";

export const SLA_RULE_CREATE_URL = `${DASHBOARD_URL}/settings/sla-rules/create`;
export const SLA_RULE_LIST_URL = `${DASHBOARD_URL}/settings/sla-rules/list`;
export const SLA_RULE_ITEM_URL = `${DASHBOARD_URL}/settings/sla-rules/item/`;

// Package Rule Settings
export const PACKAGE_RULE_CREATE_PATH = "settings/package-rules/create";
export const PACKAGE_RULE_LIST_PATH = "settings/package-rules/list";
export const PACKAGE_RULE_ITEM_PATH = "settings/package-rules/item/:id";

export const PACKAGE_RULE_CREATE_URL = `${DASHBOARD_URL}/settings/package-rules/create`;
export const PACKAGE_RULE_LIST_URL = `${DASHBOARD_URL}/settings/package-rules/list`;
export const PACKAGE_RULE_ITEM_URL = `${DASHBOARD_URL}/settings/package-rules/item/`;

// Merkatplace Settings
export const SETTINGS_MARKETPLACES_PATH = "settings/marketplaces";
export const SETTINGS_SMS_PROVIDER_PATH = "settings/sms-provider";
export const SETTINGS_MAP_PATH = "settings/map-settings";
export const SETTINGS_MARKETPLACES_URL = `${DASHBOARD_URL}/settings/marketplaces`;
export const SETTINGS_SMS_PROVIDER_URL = `${DASHBOARD_URL}/settings/sms-provider`;
export const SETTINGS_MAP_URL = `${DASHBOARD_URL}/settings/map-settings`;
export const SETTINGS_MARKETPLACE_DETAILS_URL = `${DASHBOARD_URL}/settings/marketplace-details-settings`;

export const SETTINGS_MARKETPLACE_ITEM_PATH =
  "marketplace/item(/:marketplaceId)(/:tab)";
export const SETTINGS_MARKETPLACE_ITEM_URL = `${DASHBOARD_URL}/marketplace/item/`;
export const SETTINGS_MARKETPLACE_ITEM_DETAILS_URL = `${SETTINGS_MARKETPLACE_ITEM_URL}%s/${MARKETPLACE_DETAILS_TAB}`;
export const SETTINGS_MARKETPLACE_ITEM_SETTINGS_URL = `${SETTINGS_MARKETPLACE_ITEM_URL}%s/${MARKETPLACE_SETTINGS_TAB}`;
export const SETTINGS_MARKETPLACE_ITEM_LOGO_URL = `${SETTINGS_MARKETPLACE_ITEM_URL}%s/${MARKETPLACE_LOGO_TAB}`;

// Bad Addresses Settings
export const BAD_ADDRESS_LIST_PATH = "bad-addresses";
export const BAD_ADDRESS_LIST_URL = `${DASHBOARD_URL}/bad-addresses`;

// Service Types
export const SERVICE_TYPES_LIST_PATH = "service-types";
export const SERVICE_TYPES_LIST_URL = `${DASHBOARD_URL}/service-types`;

// Bad Addresses Settings
export const ORDER_TAGS_LIST_PATH = "tags";
export const ORDER_TAGS_LIST_URL = `${DASHBOARD_URL}/tags`;

// Activity History
export const ORDER_ACTIVITY_HISTORY_PATH = "activity-history";
export const ORDER_ACTIVITY_HISTORY_URL = `${DASHBOARD_URL}/${ORDER_ACTIVITY_HISTORY_PATH}`;

// Sample Items
export const SAMPLE_ITEMS_LIST_PATH = "sample-items/list";
export const SAMPLE_ITEMS_LIST_URL = `${DASHBOARD_URL}/${SAMPLE_ITEMS_LIST_PATH}`;
export const SAMPLE_ITEM_CREATE_PATH = "sample-items/create";
export const SAMPLE_ITEMS_CREATE_URL = `${DASHBOARD_URL}/${SAMPLE_ITEM_CREATE_PATH}`;
export const SAMPLE_ITEM_PATH = "sample-items/item/:id";
export const SAMPLE_ITEM_URL = `${DASHBOARD_URL}/sample-items/item/`;

// Sample Items Settings
export const SAMPLE_ITEMS_SETTINGS_PATH = "sample-items/settings";
export const SAMPLE_ITEMS_SETTINGS_URL = `${DASHBOARD_URL}/${SAMPLE_ITEMS_SETTINGS_PATH}`;
export const SAMPLE_ITEM_CREATE_PATH_WITH_SETTINGS =
  "sample-items-settings/create";
export const SAMPLE_ITEMS_CREATE_URL_WITH_SETTINGS = `${DASHBOARD_URL}/${SAMPLE_ITEM_CREATE_PATH_WITH_SETTINGS}`;
export const SAMPLE_ITEM_PATH_WITH_SETTINGS = "sample-items-settings/item/:id";
export const SAMPLE_ITEM_URL_WITH_SETTINGS = `${DASHBOARD_URL}/sample-items-settings/item/`;

export const SUBSCRIPTION_PATH = "pricing-plans";
export const SUBSCRIPTION_URL = `${DASHBOARD_URL}/${SUBSCRIPTION_PATH}`;

export const SUBSCRIPTION_SIGN_UP_PATH = "subscription/sign-up";
export const SUBSCRIPTION_SIGN_UP_URL = `/${SUBSCRIPTION_SIGN_UP_PATH}`;

export const SUBSCRIPTION_BILLING_PATH = "billing";
export const SUBSCRIPTION_BILLING_URL = `${DASHBOARD_URL}/${SUBSCRIPTION_BILLING_PATH}`;

export const SUBSCRIPTION_PROFILE_TAB_URL = `${PROFILE_URL}/${PROFILE}`;
export const SUBSCRIPTION_PLANS_TAB_URL = `${PROFILE_URL}/${PLANS}`;
export const SUBSCRIPTION_PURCHASE_HISTORY_TAB_URL = `${PROFILE_URL}/${PURCHASE_HISTORY}`;

export const SETTINGS_PRICING_PLANS_PATH = "settings/pricing-plans";
export const SETTINGS_PRICING_PLANS_CREATE_PATH =
  "settings/pricing-plans/create";
export const SETTINGS_PRICING_PLANS_URL = `${DASHBOARD_URL}/${SETTINGS_PRICING_PLANS_PATH}`;
export const SETTINGS_PRICING_PLANS_CREATE_URL = `${DASHBOARD_URL}/${SETTINGS_PRICING_PLANS_CREATE_PATH}`;
export const SETTINGS_PRICING_PLANS_ITEM_PATH =
  "settings/pricing-plans/item/:id";
export const SETTINGS_PRICING_PLANS_ITEM_URL = `${DASHBOARD_URL}/settings/pricing-plans/item/`;

// Terms Settings

export const TERMS_SETTINGS_PATH = "settings/terms-settings/list";
export const TERMS_SETTINGS_URL = `${DASHBOARD_URL}/${TERMS_SETTINGS_PATH}`;

export const TERMS_SETTINGS_LIST_PATH = "settings/terms-settings/list";

export const TERMS_SETTINGS_CREATE_PATH = "settings/terms-settings/create";

export const TERMS_SETTINGS_CREATE_URL = `${DASHBOARD_URL}/${TERMS_SETTINGS_CREATE_PATH}`;
export const TERMS_SETTINGS_ITEM_PATH = "settings/terms-settings/item/:id";
export const TERMS_SETTINGS_ITEM_URL = `${DASHBOARD_URL}/settings/terms-settings/item/`;

// Default Terms Settings

export const DEFAULT_TERMS_SETTINGS_PATH = "settings/default-terms-settings";
export const DEFAULT_TERMS_SETTINGS_URL = `${DASHBOARD_URL}/${DEFAULT_TERMS_SETTINGS_PATH}`;

export const DEFAULT_TERMS_SETTINGS_LIST_PATH =
  "settings/default-terms-settings";

// Pdf Settings
export const PDF_SETTINGS_PATH = "settings/pdf-template/list";

// Pdf Settings GRID page
export const PDF_SETTINGS_URL = `${DASHBOARD_URL}/settings/pdf-template/list`;
export const PDF_TEMPLATE_CREATE_PATH = "settings/pdf-template/create";
export const PDF_TEMPLATE_ITEM_PATH = "settings/pdf-template/item/:id";

export const PDF_TEMPLATE_CREATE_URL = `${DASHBOARD_URL}/settings/pdf-template/create`;
export const PDF_TEMPLATE_ITEM_URL = `${DASHBOARD_URL}/settings/pdf-template/item/`;

// Pdf Settings HTML page
export const PDF_TEMPLATE_CREATE_HTML_PATH =
  "settings/pdf-template/create-html";
export const PDF_TEMPLATE_HTML_ITEM_PATH =
  "settings/pdf-template/html-item/:id";

export const PDF_TEMPLATE_CREATE_HTML_URL = `${DASHBOARD_URL}/settings/pdf-template/create-html`;
export const PDF_TEMPLATE_HTML_ITEM_URL = `${DASHBOARD_URL}/settings/pdf-template/html-item/`;

// Jurisdiction Management
export const JURISDICTION_MANAGEMENT_PATH = "jurisdiction-management";
export const JURISDICTION_MANAGEMENT_URL = `${DASHBOARD_URL}/${JURISDICTION_MANAGEMENT_PATH}`;

// Additional Services

export const ADDITIONAL_SERVICES_SETTINGS_LIST_PATH =
  "settings/additional_services/list";
export const ADDITIONAL_SERVICES_SETTINGS_URL = `${DASHBOARD_URL}/${ADDITIONAL_SERVICES_SETTINGS_LIST_PATH}`;
export const ADDITIONAL_SERVICES_SETTINGS_CREATE_PATH =
  "settings/additional_services/create";
export const ADDITIONAL_SERVICES_SETTINGS_CREATE_URL = `${DASHBOARD_URL}/${ADDITIONAL_SERVICES_SETTINGS_CREATE_PATH}`;
export const ADDITIONAL_SERVICES_SETTINGS_ITEM_PATH =
  "settings/additional_services/item/:id";
export const ADDITIONAL_SERVICES_SETTINGS_ITEM_URL = `${DASHBOARD_URL}/settings/additional_services/item/`;

// Route show details
export const ROUTE_ITEM_PATH = "route/item/:id";
export const ROUTE_ITEM_URL = `${DASHBOARD_URL}/route/item/`;

// Batch categories
export const BATCH_CATEGORIES_LIST_PATH = "batch-categories";
export const BATCH_CATEGORIES_LIST_URL = `${DASHBOARD_URL}/${BATCH_CATEGORIES_LIST_PATH}`;

export const OPIS_CATEGORIES_LIST_PATH = "opis-categories";
export const OPIS_CATEGORIES_LIST_URL = `${DASHBOARD_URL}/${OPIS_CATEGORIES_LIST_PATH}`;

export const REPORT_WAREHOUSE_PATH = "report_warehouse";
export const REPORT_WAREHOUSE_URL = `${DASHBOARD_URL}/report_warehouse`;
export const HYBRID_PATH = "hybrid";
export const HYBRID_URL = `${DASHBOARD_URL}/hybrid`;

export const CUSTOM_BATCH_UPDATE_PATH = "custom_batch_update";
export const CUSTOM_BATCH_UPDATE_URL = `${DASHBOARD_URL}/custom_batch_update`;

export const SHIFTING_HISTORY_PATH = "shifting-history";
export const SHIFTING_HISTORY_URL = `${DASHBOARD_URL}/shifting-history`;

export const INTERNATIONAL_LETTER_PATH = "international_letter";
export const INTERNATIONAL_LETTER_URL = `${DASHBOARD_URL}/international_letter`;

export const INTERNATIONAL_PARCEL_PATH = "international_parcel";
export const INTERNATIONAL_PARCEL_URL = `${DASHBOARD_URL}/international_parcel`;

export const INTERNATIONAL_EMPTY_BAGS_PATH = "international-empty-bags";
export const INTERNATIONAL_EMPTY_BAGS_URL = `${DASHBOARD_URL}/international-empty-bags`;

export const INTERNATIONAL_IN_TARIFF_PATH = "international_in_tariff";
export const INTERNATIONAL_IN_TARIFF_URL = `${DASHBOARD_URL}/international_in_tariff`;

export const INTERNATIONAL_IN_TARIFF_CREATE_PATH =
  "international_in_tariff/create";
export const INTERNATIONAL_IN_TARIFF_CREATE_URL = `${DASHBOARD_URL}/international_in_tariff/create`;

export const INTERNATIONAL_IN_TARIFF_ITEM_PATH =
  "international_in_tariff/item/:id";
export const INTERNATIONAL_IN_TARIFF_ITEM_URL = `${DASHBOARD_URL}/international_in_tariff/item/`;

export const INTERNATIONAL_OUT_TARIFF_PATH = "international_tariff";
export const INTERNATIONAL_OUT_TARIFF_URL = `${DASHBOARD_URL}/international_tariff`;

export const INTERNATIONAL_OUT_TARIFF_CREATE_PATH =
  "international_out_tariff/create";
export const INTERNATIONAL_OUT_TARIFF_CREATE_URL = `${DASHBOARD_URL}/international_out_tariff/create`;

export const INTERNATIONAL_OUT_TARIFF_ITEM_PATH =
  "international_out_tariff/item/:id";
export const INTERNATIONAL_OUT_TARIFF_ITEM_URL = `${DASHBOARD_URL}/international_out_tariff/item/`;

export const INTERNATIONAL_SETTINGS_PATH = "international_settings";
export const INTERNATIONAL_SETTINGS_URL = `${DASHBOARD_URL}/international_settings`;
export const INTERNATIONAL_SETTINGS_COUNTRIES_PATH =
  "international_settings/countries";
export const INTERNATIONAL_SETTINGS_COUNTRIES_URL = `${DASHBOARD_URL}/international_settings/countries`;

export const INTERNATIONAL_SETTINGS_FORMAT_PATH =
  "international_settings/format";
export const INTERNATIONAL_SETTINGS_FORMAT_URL = `${DASHBOARD_URL}/international_settings/format`;
export const INTERNATIONAL_SETTINGS_CATEGORY_PATH =
  "international_settings/category";
export const INTERNATIONAL_SETTINGS_CATEGORY_URL = `${DASHBOARD_URL}/international_settings/category`;
export const INTERNATIONAL_SETTINGS_SUB_CLASSES_PATH =
  "international_settings/subclasses";
export const INTERNATIONAL_SETTINGS_SUB_CLASSES_URL = `${DASHBOARD_URL}/international_settings/subclasses`;
export const INTERNATIONAL_SETTINGS_TYPES_PATH = "international_settings/types";
export const INTERNATIONAL_SETTINGS_TYPES_URL = `${DASHBOARD_URL}/international_settings/types`;

export const ACT_MANAGEMENT_PATH = "act-management";
export const ACT_MANAGEMENT_LOCAL_CREATE_PATH = "act-management/local/create";
export const ACT_MANAGEMENT_URL = `${DASHBOARD_URL}/act-management`;
export const ACT_MANAGEMENT_LOCAL_CREATE_URL = `${DASHBOARD_URL}/act-management/local/create`;
